<template>
  <div>
    <div class="content">
      <div class="title">常用短语 <i class="el-icon-circle-plus-outline" @click="addGroup" /></div>
      <div class="desc">内容编辑后默认保存，即时生效。</div>
      <div v-for="(item,index) in answerTree" :key="index" class="group">
        <div class="father">
          <i :class="item.fold ? 'el-icon-arrow-down':'el-icon-arrow-up'" @click="changeFold(index)" />
          <el-input v-model="item.groupName" class="group-input" @change="groupChange(item)" />
          <i class="el-icon-circle-plus-outline margin-r-20" @click="addChild(index)" />
          <i class="el-icon-delete" @click="deletefather(item.id)" />
        </div>
        <div v-if="item.fold" class="child">
          <div v-for="(item1,index1) in item.fastAnswerItemDtoList" :key="index1" class="child-item">
            <el-input v-model="item1.answer" @change="answerChange(item1)" class="group-input" />
            <i class="el-icon-delete" @click="deleteClild(item1.id)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import md5 from "blueimp-md5";
export default {
  props:{
    app:String,
    serviceCode: String,
    actionCode: String
  },
  data() {
    return {
      active: 3,
      answerTree: []
    }
  },
  mounted() {
    this.getAnswerTree()
  },
  methods: {
    groupChange(item) {
      const time = new Date()
      const form = {
        id: item.id ? item.id : '',
        serviceCode:this.serviceCode,
        groupName:item.groupName,
        app:this.app,
        actionTime:time,
        sign: md5(time.getTime() + this.actionCode)
      }
      request.post('/api/answer/setAnswerGroup',form).then(res => {
        this.getAnswerTree()
      })
    },
    answerChange(item) {
      const time = new Date()
      const form = {
        id: item.id ? item.id : '',
        groupId: item.groupId,
        answer:item.answer,
        app:this.app,
        serviceCode:this.serviceCode,
        actionTime:time,
        sign: md5(time.getTime() + this.actionCode)
      }
      request.post('/api/answer/setAnswerItem', form).then(res => {
        this.getAnswerTree()
      })
    },
    getAnswerTree() {
      request.get(`/api/answer/getAnswerTree?app=${this.app}&serviceCode=${this.serviceCode}`).then((res) => {
        res.forEach((item) => {
          item.fold = true;
        });
        this.answerTree = res;
      });
    },
    addGroup() {
      this.answerTree.push({ fold: false, groupName: '',fastAnswerItemDtoList: [] })
    },
    deletefather(id) {
      const time = new Date()
      const form = {
        id,
        app:this.app,
        serviceCode:this.serviceCode,
        actionTime:time,
        sign: md5(time.getTime() + this.actionCode)
      }
      console.log(form);
      request.post(`/api/answer/deleteAnswerGroup`,form).then(res => {
        this.getAnswerTree()
      })
    },
    addChild(index) {
      this.answerTree[index].fastAnswerItemDtoList.push({ answer: '', groupId:this.answerTree[index].id })
      console.log(this.answerTree);
    },
    deleteClild(id) {
      const time = new Date()
      const form = {
        id,
        app:this.app,
        serviceCode:this.serviceCode,
        actionTime:time,
        sign: md5(time.getTime() + this.actionCode)
      }
      request.post(`/api/answer/deleteAnswerItem`,form).then(res => {
        this.getAnswerTree()
      })
    },
    changeFold(index) {
      this.answerTree[index].fold = !this.answerTree[index].fold
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  border-radius: 50px;
  font-size: 20px;
  padding: 20px 32px;
  box-shadow: 1px 1px 10px 5px #f5f5f5;
  margin-bottom: 15px;
  &-item {
    margin-right: 32px;
    cursor: pointer;
  }
  .blue {
    color: #087CF2;
  }
}
.content {
  padding: 20px 32px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px 5px #f5f5f5;
  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .desc {
    color: #BCBCBC;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .btn-box {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
  .input-box {
    margin: 12px 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .problem {
      width: 300px;
      margin: 0 20px;
    }
    .answer {
      margin-right: 20px;
    }
  }
  .group {
    .group-input {
      margin: 0 20px;
    }
    .father {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .child {
      margin-bottom: 20px;
      padding-left: 40px;
      .child-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
}
.margin-r-20 {
  margin-right: 20px;
}
</style>
