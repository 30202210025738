<template>
  <div class="mobile-box" :style="active == 4 ? 'padding-bottom:0px;' : ''">
    <div v-if="active == 1">
      <div class="state">
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="color: green" v-if="isOnline">客服在线</span>
            <span v-else>客服离线</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="isOnline" command="1"
              >在线</el-dropdown-item
            >
            <el-dropdown-item :disabled="!isOnline" command="2"
              >离线</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="list-item" @click="goWait">
        <div class="list-item-header">
          <span class="wait-title">待接入用户</span>
        </div>
        <div class="list-item-footer grey">{{ waitNum }}人待回复</div>
      </div>
      <div
        class="list-item"
        v-for="(item, index) in customerList"
        :key="index"
        @click="clickCustomer(item, 2)"
      >
        <div class="list-item-header">
          <span class="customer-name">{{
            item.customerName || item.name
          }}</span>
          <span class="time">{{ item.createAt | formatTime('{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          <span class="no-read" v-if="item.toReadNum > 0">{{
            item.toReadNum
          }}</span>
        </div>
        <div class="list-item-footer">
          {{ changeContent(item.content,item.contentType) }}
        </div>
      </div>
      <el-empty v-if="customerList.length == 0" description="暂无接入客户"></el-empty>
    </div>
    <div class="tab-bar" v-if="active != 4">
      <div class="tab-bar-item">
        <img src="../../assets/images/setting-s.png" alt="" />
        <div style="color: #56FB61;">聊天</div>
      </div>
      <div class="tab-bar-item" @click="goReply">
        <img src="../../assets/images/im.png" alt="" />
        <div>快捷回复</div>
      </div>
      <div class="tab-bar-item" @click="goLeave">
        <img src="../../assets/images/liuyanm.png" alt="" />
        <div>留言</div>
      </div>
    </div>
  </div>
</template>

<script>
import { setRemBaseFontSize } from "@/utils/common";
import {
  serviceOffline,
  serviceOnline,
  handleCommand,
  getContent2,
  getContent,
  websocketOpen,
  websocketClose,
  websocketError,
  reconnect,
  readMessage,
  scrollToBottom
} from "./index";
import request from '@/utils/request'
export default {
  data() {
    return {
      active: 1,
      sessionId: null,
      list: [],
      isShowMore: true,
      send: "",
      roomId: "",
      customerList: [],
      isLoading: false,
      waitNum: 0,
      customerCode: "",
      lockReconnect: false,
      isOnline: false,
      answerTree: [],
      serviceCode: "",
      serviceName: "",
      app: "",
      actionCode: "",
      tt: null,
      waitCustomerList: [],
      isManualDisconnect:false
    };
  },
  created() {
    document.title = '已接入列表'
    this.setRemBaseFontSize();
    // 监听窗口大小变化事件，以更新基准字体大小
    window.addEventListener("resize", this.setRemBaseFontSize);
  },
  mounted() {
    this.serviceCode = this.$route.query.serviceCode;
    this.serviceName = this.$route.query.serviceName;
    this.actionCode = this.$route.query.actionCode;
    this.app = this.$route.query.app;
    this.initWebSocket();
  },
  beforeDestroy() {
    this.isManualDisconnect = true
    this.ws.close()
  },
  methods: {
    setRemBaseFontSize,
    handleCommand,
    serviceOffline,
    serviceOnline,
    getContent2,
    getContent,
    websocketOpen,
    websocketClose,
    websocketError,
    reconnect,
    readMessage,
    scrollToBottom,
    changeContent(content,type) {
      if(type == 'word') {
        return content
      }else if(type == 'vedio') {
        return '[视频]'
      }else if(type == 'audio') {
        return '[音频]'
      }else if(type == 'img') {
        return '[图片]'
      }else if(type == 'file') {
        return '[附件]'
      }else if(type == 'url') {
        return '[超链接]'
      }
    },
    clickCustomer(item, type) {
      this.roomId = item.roomId;
      this.customerCode = item.customerCode;
      this.list = [];
      this.isShowMore = true;
      if (type == 2) {
        item.toReadNum = 0;
        this.readMessage();
      }
      this.$router.push(`/phoneServiceIm?roomId=${this.roomId}&app=${this.app}&customerCode=${this.customerCode}&serviceCode=${this.serviceCode}&name=${item.customerName || item.name}`)
    },
    connectCustomers(roomId) {
      this.roomId = roomId;
      const form = {
        roomId,
        serviceCode: this.serviceCode,
        serviceName: this.serviceName,
        sessionId: this.sessionId,
        app: this.app,
      };
      request.post("/api/ws/connectCustomer", form).then((res) => {
        this.getContent();
      });
    },
    goWait() {
      this.$router.push(`/waitList?app=${this.app}&serviceCode=${this.serviceCode}&serviceName=${this.serviceName}&sessionId=${this.sessionId}`)
    },
    goLeave() {
      this.$router.push(
        `/leaveMessage?app=${this.app}&serviceCode=${this.serviceCode}&actionCode=${this.actionCode}`
      );
    },
    goReply() {
      this.$router.push(
        `/reply?app=${this.app}&serviceCode=${this.serviceCode}&actionCode=${this.actionCode}`
      );
    },
    initWebSocket() {
      const that = this;
      this.ws = new WebSocket(this.$wsUrl);
      this.ws.onmessage = function (event) {
        that.tt && clearInterval(that.tt);
        const data = JSON.parse(event.data);
        if (data.type == "session") {
          that.sessionId = data.data;
          that.serviceOnline();
          that.customerList = [];
          that.getContent2();
          that.getContent();
        }
        if (data.type == "content") {
          data.data.who = "user";
          if (that.roomId == data.data.roomId) {
            that.list.push(data.data);
          } else {
            for (let i = 0; i < that.customerList.length; i++) {
              if (that.customerList[i].roomId == data.data.roomId) {
                that.customerList[i].contentType = data.data.contentType;
                that.customerList[i].content = data.data.content;
                that.customerList[i].toReadNum += data.data.toReadNum
                return;
              }
            }
          }
        }
        if (data.type == "num") {
          that.getContent2();
        }
        if(data.type == 'connected') {
          that.getContent()
        }
        console.log(`收到消息：${event.data}`);
      };
      this.ws.onopen = this.websocketOpen;
      this.ws.onerror = this.websocketError;
      this.ws.onclose = this.websocketClose;
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-box {
  height: 100vh;
  box-sizing: border-box;
  font-size: 0.16rem;
  padding-bottom: 0.7rem;
}
.state {
  padding: 0.12rem;
  background-color: #ededed;
}
.list-item {
  width: calc(100% - 0.28rem);
  padding: 0.12rem 0;
  margin: 0 0.12rem;
  border-bottom: 1px solid #f5f5f5;
  &-header {
    color: #898989;
    margin-bottom: 0.05rem;
    display: flex;
    align-items: center;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .wait-title {
    font-weight: bold;
    color: #333;
  }
  .customer-name {
    color: #333;
    margin-right: 0.1rem;
  }
  .time {
    flex: 1;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  &-footer {
    color: #898989;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .no-read {
    color: #fff;
    text-align: center;
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    margin-left: 0.1rem;
    background-color: red;
    display: inline-block;
  }
}
.grey {
  color: #898989;
}
.tab-bar {
  display: flex;
  position: fixed;
  bottom: 0;
  padding-bottom: 0.05rem;
  left: 0;
  height: 0.6rem;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  &-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #BFBFBF;
    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-bottom: 0.05rem;
    }
  }
}
</style>
