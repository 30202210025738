<template>
  <div class="im">
    <serviceImMobile v-if="isShowMobile == true" />
    <serviceImPc v-if="isShowMobile == false" />
  </div>
</template>

<script>
import serviceImMobile from '@/components/serviceIm/serviceImMobile.vue'
import serviceImPc from '@/components/serviceIm/serviceImPc.vue'
export default {
  components: {
    serviceImMobile,
    serviceImPc
  },
  data() {
    return {
      isShowMobile: null
    }
  },
  created() {
    this.isShowMobile = this.isMobile()
  },
  methods:{
    isMobile() {
      return /Android|iPhone|iPad|iPod|Windows Phone|Mobile/i.test(navigator.userAgent);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
