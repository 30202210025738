<template>
  <div>
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="[
          'tab-item',
          form.param.dealStatus == item.active ? 'blue' : '',
        ]"
        @click="clickTab(item.active)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="content" label="内容"> </el-table-column>
        <el-table-column prop="userName" label="用户名"> </el-table-column>
        <el-table-column prop="mobile" label="手机号"> </el-table-column>
        <el-table-column prop="createAt" label="时间">
          <template slot-scope="{ row }">
            <div>
              {{ row.createAt | formatTime('{y}-{m}-{d} {h}:{i}:{s}') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="onClick(row.id)" v-if="form.param.dealStatus != 1">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="query"
        :current-page.sync="form.pageNum"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <el-dialog
        title="处理留言"
        :visible.sync="visible"
        width="80%"
        :before-close="handleClose">
        <el-form label-position="left" ref="form" :model="form" label-width="80px">
            <el-form-item label="您的姓名">
                <el-input v-model="form.userName" />
            </el-form-item>
            <el-form-item label="电话号码">
                <el-input v-model="form.mobile" />
            </el-form-item>
            <el-form-item label="留言内容">
                <el-input v-model="form.content" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="addLeave">确 定</el-button>
        </span>
    </el-dialog> -->
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  props: {
    app: String,
    serviceCode: String,
    actionCode: String,
  },
  data() {
    return {
      tabList: [
        { active: 0, name: "未处理" },
        { active: 1, name: "已处理" },
      ],
      form: {
        pageNum: 1,
        pageSize: 10,
        param: {
          dealStatus: "0",
          app:''
        },
      },
      tableData: [],
      total: 0,
      handleId: null,
    };
  },
  mounted() {
    this.form.param.app = this.app
    this.query();
  },
  methods: {
    clickTab(e) {
      this.form.param.dealStatus = e
      this.query()
    },
    onClick(id) {
      this.$confirm(
        "是否处理留言？",
        "提示",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const form = {
            id,
            app:this.app
          }
          request.post('/api/leaveMessage/complete',form).then(res => {
            this.$message.success('处理成功')
            this.query()
          })
        })
        .catch((action) => {
          
        });
      
    },
    async query(num) {
      this.form.pageNum = isNaN(num) ? 1 : num;
      this.form.param.app = this.app
      const res = await request.post(
        "/api/leaveMessage/getPageInfo",
        this.form
      );
      const { list, total: newTotal } = res;
      this.total = newTotal;
      this.tableData = list;
    },
    handleSizeChange(size) {
      this.form.pageSize = size;
      query();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  border-radius: 50px;
  font-size: 20px;
  padding: 20px 32px;
  box-shadow: 1px 1px 10px 5px #f5f5f5;
  margin-bottom: 15px;
  &-item {
    margin-right: 32px;
    cursor: pointer;
  }
  .blue {
    color: #087cf2;
  }
}
.content {
  padding: 20px 32px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px 5px #f5f5f5;
}
</style>
