<template>
  <div class="im">
    <div class="im-box">
      <div class="im-box-content">
        <div class="im-box-content-tools">
          <el-dropdown @command="handleCommand">
            <img
              class="im-box-content-tools-item"
              :src="
                isOnline
                  ? require('../../assets/images/online.png')
                  : require('../../assets/images/offline.png')
              "
              alt=""
            />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="isOnline" command="1"
                >在线</el-dropdown-item
              >
              <el-dropdown-item :disabled="!isOnline" command="2"
                >离线</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <img
            class="im-box-content-tools-item"
            :src="
              toolsActive == 1
                ? require('../../assets/images/im-s.png')
                : require('../../assets/images/im.png')
            "
            alt=""
            @click="clickTools(1)"
          />
          <img
            class="im-box-content-tools-item"
            :src="
              toolsActive == 2
                ? require('../../assets/images/setting-s.png')
                : require('../../assets/images/setting.png')
            "
            alt=""
            @click="clickTools(2)"
          />
          <img
            class="im-box-content-tools-item"
            :src="
              toolsActive == 3
                ? require('../../assets/images/liuyanm-s.png')
                : require('../../assets/images/liuyanm.png')
            "
            alt=""
            @click="clickTools(3)"
          />
        </div>
        <div v-if="toolsActive == 1" class="flex flex1 im-active1">
          <div class="im-box-content-right">
            <div class="im-box-content-head">
              待接入<span class="margin-l-5">{{ waitNum }}</span>
            </div>
            <div class="im-box-content-list">
              <div
                v-for="(item, index) in waitCustomerList"
                :key="index"
                :class="[
                  'customer-item',
                  item.customerCode == customerCode
                    ? 'customer-item-active'
                    : '',
                ]"
                @click="clickCustomer(item, 1)"
              >
                <div class="customer-item-left">
                  <div class="customer-item-name">
                    {{ item.customerName || item.name }}
                  </div>
                  <div class="customer-item-content">
                    <div
                      v-if="item.contentType == 'word'"
                      class="customer-item-content-text"
                    >
                      {{ item.content }}
                    </div>
                    <div
                      v-else-if="item.contentType == 'vedio'"
                      class="customer-item-content-text"
                    >
                      [视频]
                    </div>
                    <div
                      v-else-if="item.contentType == 'audio'"
                      class="customer-item-content-text"
                    >
                      [音频]
                    </div>
                    <div
                      v-else-if="item.contentType == 'img'"
                      class="customer-item-content-text"
                    >
                      [图片]
                    </div>
                    <div
                      v-else-if="item.contentType == 'file'"
                      class="customer-item-content-text"
                    >
                      [附件]
                    </div>
                    <div
                      v-else-if="item.contentType == 'url'"
                      class="customer-item-content-text"
                    >
                      [超链接]
                    </div>
                    <div class="customer-item-content-time">
                      {{
                        item.createAt | formatTime("{y}-{m}-{d} {h}:{i}:{s}")
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="im-box-content-head">已接入</div>
            <div class="im-box-content-list">
              <div
                v-for="(item, index) in customerList"
                :key="index"
                :class="[
                  'customer-item',
                  item.customerCode == customerCode
                    ? 'customer-item-active'
                    : '',
                ]"
                @click="clickCustomer(item, 2)"
              >
                <div class="customer-item-left">
                  <div class="customer-item-name">
                    <div style="flex: 1">
                      {{ item.customerName || item.name }}
                    </div>
                    <div v-if="item.toReadNum > 0" class="item-no-read">
                      {{ item.toReadNum }}
                    </div>
                  </div>
                  <div class="customer-item-content">
                    <div
                      v-if="item.contentType == 'word'"
                      class="customer-item-content-text"
                    >
                      {{ item.content }}
                    </div>
                    <div
                      v-else-if="item.contentType == 'vedio'"
                      class="customer-item-content-text"
                    >
                      [视频]
                    </div>
                    <div
                      v-else-if="item.contentType == 'audio'"
                      class="customer-item-content-text"
                    >
                      [音频]
                    </div>
                    <div
                      v-else-if="item.contentType == 'img'"
                      class="customer-item-content-text"
                    >
                      [图片]
                    </div>
                    <div
                      v-else-if="item.contentType == 'file'"
                      class="customer-item-content-text"
                    >
                      [附件]
                    </div>
                    <div
                      v-else-if="item.contentType == 'url'"
                      class="customer-item-content-text"
                    >
                      [超链接]
                    </div>
                    <div class="customer-item-content-time">
                      {{
                        item.createAt | formatTime("{y}-{m}-{d} {h}:{i}:{s}")
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="im-box-content-left">
            <div ref="record" class="im-record">
              <div
                v-if="list.length !== 0"
                class="more"
                @click="getHistory(list[0].contentId)"
              >
                <el-divider content-position="center">{{
                  isShowMore ? "更多消息" : "暂无更多消息"
                }}</el-divider>
              </div>
              <div v-for="(item, index) in list" :key="index">
                <div v-if="item.who == 'service'" class="record-item-me">
                  <div class="record-item-me-name">
                    {{
                      item.serviceCode == serviceCode ? "我" : item.serviceName
                    }}
                  </div>
                  <span
                    v-if="item.contentType == 'word'"
                    class="record-item-me-text"
                    style="white-space: pre-wrap"
                    >{{ item.content }}</span
                  >
                  <span
                    v-else-if="item.contentType == 'img'"
                    class="record-item-me-text"
                  >
                    <img class="record-img" :src="item.content" alt="" />
                  </span>
                  <span
                    v-else-if="item.contentType == 'vedio'"
                    class="record-item-me-text"
                  >
                    <video
                      controls
                      class="record-img"
                      :src="item.content"
                    ></video>
                  </span>
                  <span
                    v-else-if="item.contentType == 'audio'"
                    class="record-item-me-text"
                  >
                    <audio controls :src="item.content" class="record-img">
                      您的浏览器不支持 audio 元素。
                    </audio>
                  </span>
                  <span v-else class="record-item-me-text">
                    <a target="_blank" :href="item.content">{{
                      item.content
                    }}</a>
                  </span>
                </div>
                <div v-else class="record-item-other">
                  <div class="record-item-other-name">
                    {{ item.customerName || item.name }}
                  </div>
                  <span
                    v-if="item.contentType == 'word'"
                    class="record-item-other-text"
                    style="white-space: pre-wrap"
                    >{{ item.content }}</span
                  >
                  <span
                    v-else-if="item.contentType == 'img'"
                    class="record-item-other-text"
                  >
                    <img class="record-img" :src="item.content" alt="" />
                  </span>
                  <span
                    v-else-if="item.contentType == 'vedio'"
                    class="record-item-other-text"
                  >
                    <video
                      controls
                      class="record-img"
                      :src="item.content"
                    ></video>
                  </span>
                  <span
                    v-else-if="item.contentType == 'audio'"
                    class="record-item-other-text"
                  >
                    <audio controls :src="item.content" class="record-img">
                      您的浏览器不支持 audio 元素。
                    </audio>
                  </span>
                  <span v-else class="record-item-me-text">
                    <a target="_blank" :href="item.content">{{
                      item.content
                    }}</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="im-send">
              <div class="im-send-tools">
                <!-- <img src="../assets/biaoqing.png" class="im-send-tools-item" alt="" /> -->
                <el-upload
                  :show-file-list="false"
                  action=""
                  :http-request="upload"
                  :limit="1"
                >
                  <!-- <img
                    src="../../assets/images/tupian.png"
                    class="im-send-tools-item"
                    alt=""
                  /> -->
                  <div class="send-tools-item">图片</div>
                </el-upload>
                <div class="send-tools-item" @click="clickTransfer">转接</div>
              </div>
              <div class="im-send-content">
                <el-input
                  v-model="send"
                  class="im-input"
                  type="textarea"
                  placeholder="请输入内容"
                  @keydown.ctrl.enter="handleEnter"
                  @keydown.enter.exact.native.prevent="clickSend(send, 'word')"
                />
                <div class="im-send-content-tip">
                  按Enter发送，Shift+Enter换行
                </div>
              </div>
            </div>
          </div>
          <div class="im-box-content-fast">
            <div class="im-box-content-fast-head">
              <div
                :class="[
                  'im-box-content-fast-head-item',
                  fastActive == 2 ? 'im-box-content-fast-head-item-active' : '',
                ]"
                @click="clickFastHeader(2)"
              >
                快捷回复
              </div>
              <!-- <div
                :class="[
                  'im-box-content-fast-head-item',
                  fastActive == 1 ? 'im-box-content-fast-head-item-active' : '',
                ]"
                @click="clickFastHeader(1)"
              >
                基础信息
              </div> -->
            </div>
            <div v-if="fastActive == 1">
              <!-- <div class="">用户信息</div> -->
            </div>
            <div v-if="fastActive == 2">
              <div class="group">
                <div
                  v-for="(item, index) in answerTree"
                  :key="index"
                  class="group-item"
                >
                  <div class="group-item-f" @click="item.show = !item.show">
                    <i
                      :class="
                        item.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                      "
                    />
                    {{ item.groupName }}
                  </div>
                  <div
                    v-for="(item1, index1) in item.fastAnswerItemDtoList"
                    :key="index1"
                    class="children"
                  >
                    <div
                      v-if="item.show"
                      class="children-item"
                      @click="clickSend(item1.answer, 'word')"
                    >
                      {{ item1.answer }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="toolsActive == 2" class="flex1 term">
          <term
            :app="app"
            :serviceCode="serviceCode"
            :actionCode="actionCode"
          />
        </div>
        <div v-else-if="toolsActive == 3" class="flex1 term">
          <leave
            :app="app"
            :serviceCode="serviceCode"
            :actionCode="actionCode"
          />
        </div>
      </div>
    </div>
    <div class="support">佳鲸通提供客服软件支持</div>
    <el-dialog
      title="转接"
      :visible.sync="visible"
      width="30%"
    >
      <el-form>
        <el-form-item label="转接人员">
          <el-select v-model="transferCode" filterable>
            <el-option v-for="item in serviceList" :key="item.serviceCode" :value="item.serviceCode" :label="item.serviceName"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="transfer">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request.js";
import term from "@/components/term.vue";
import leave from "@/components/leave.vue";
import {
  serviceOffline,
  serviceOnline,
  handleCommand,
  isURL,
  upload,
  sockClose,
  getHistory,
  scrollToBottom,
  clickSend,
  getContent2,
  getContent,
  websocketOpen,
  websocketClose,
  websocketError,
  reconnect,
  readMessage,
} from "./index";
export default {
  components: {
    term,
    leave,
  },
  data() {
    return {
      sessionId: null,
      list: [],
      isShowMore: true,
      send: "",
      roomId: "",
      customerList: [],
      isLoading: false,
      waitNum: 0,
      customerCode: "",
      lockReconnect: false,
      isOnline: false,
      fastActive: 2,
      toolsActive: 1,
      answerTree: [],
      serviceCode: "",
      serviceName: "",
      app: "",
      actionCode: "",
      tt: null,
      waitCustomerList: [],
      isManualDisconnect: false,
      visible:false,
      serviceList: [],
      transferCode: '',
      customerName: ''
    };
  },
  mounted() {
    this.serviceCode = this.$route.query.serviceCode;
    this.serviceName = this.$route.query.serviceName;
    this.actionCode = this.$route.query.actionCode;
    this.app = this.$route.query.app;
    this.initWebSocket();
    this.getAnswerTree();
    this.getServiceInfo()
  },
  beforeDestroy() {
    this.tt && clearInterval(this.tt);
  },
  methods: {
    serviceOffline,
    serviceOnline,
    isURL,
    upload,
    handleCommand,
    sockClose,
    getHistory,
    scrollToBottom,
    clickSend,
    getContent2,
    getContent,
    websocketOpen,
    websocketClose,
    websocketError,
    reconnect,
    readMessage,
    clickTransfer() {
      this.visible = true
      this.transferCode = ''
    },
    transfer() {
      const form = {
        app:this.app,
        customerCode:this.customerCode,
        customerName: this.customerName,
        roomId: this.roomId,
        serviceCode: this.transferCode
      }
      request.post('/api/ws/transfer',form).then(res => {
        this.visible = false
        this.$message.success('转接成功')
        this.list = []
        this.isShowMore = true;
        this.getContent()
        this.customerCode = ''
      })
    },
    getServiceInfo() {
      request.get('/api/service/getServiceInfo',{app:this.app}).then(res => {
        this.serviceList = res
      })
    },
    clickTools(e) {
      this.toolsActive = e;
    },
    getAnswerTree() {
      request
        .get(
          `/api/answer/getAnswerTree?app=${this.app}&serviceCode=${this.serviceCode}`
        )
        .then((res) => {
          res.forEach((item) => {
            item.show = true;
          });
          this.answerTree = res;
        });
    },
    clickFastHeader(e) {
      this.fastActive = e;
    },
    handleEnter(event) {
      if (event.shiftKey && event.code === "Enter") {
        this.send += "\n"; // 在输入内容后面添加换行符号
      }
    },

    clickCustomer(item, type) {
      this.roomId = item.roomId;
      this.customerCode = item.customerCode;
      this.customerName = item.customerName || item.name
      if (type == 1) {
        this.connectCustomers(this.roomId);
      }

      if (type == 2) {
        this.list = [];
        this.isShowMore = true;
        this.getHistory();
        item.toReadNum = 0;
        this.readMessage();
      }
    },
    connectCustomers(roomId) {
      this.roomId = roomId;
      const form = {
        roomId,
        serviceCode: this.serviceCode,
        serviceName: this.serviceName,
        sessionId: this.sessionId,
        app: this.app,
        customerCode: this.customerCode,
      };
      request
        .post("/api/ws/connectCustomer", form)
        .then((res) => {
          this.list = [];
          this.isShowMore = true;
          this.getHistory();
          this.getContent();
        })
        .catch(() => {
          this.list = [];
          this.isShowMore = true;
          this.getContent2();
        });
    },

    initWebSocket() {
      const that = this;
      this.ws = new WebSocket(this.$wsUrl);
      this.ws.onmessage = function (event) {
        that.tt && clearInterval(that.tt);
        const data = JSON.parse(event.data);
        if (data.type == "session") {
          that.sessionId = data.data;
          that.serviceOnline();
          that.customerList = [];
          that.getContent2();
          that.getContent();
        }
        if (data.type == "content") {
          data.data.who = "user";
          if (that.roomId == data.data.roomId) {
            that.list.push(data.data);
            that.readMessage();
          } else {
            for (let i = 0; i < that.customerList.length; i++) {
              if (that.customerList[i].roomId == data.data.roomId) {
                that.customerList[i].contentType = data.data.contentType;
                that.customerList[i].content = data.data.content;
                that.customerList[i].toReadNum = data.data.toReadNum;
                return;
              }
            }
          }
          const element = that.$refs.record;
          element.scrollTop = element.scrollHeight;
        }
        if (data.type == "num") {
          // that.waitNum = data.data;
          that.getContent2();
        }
        if(data.type == 'connected') {
          that.getContent()
        }
        console.log(`收到消息：${event.data}`);
      };
      this.ws.onopen = this.websocketOpen;
      this.ws.onerror = this.websocketError;
      this.ws.onclose = this.websocketClose;
    },
  },
};
</script>

<style lang="scss" scoped>
.im {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: #e6e6e6;
  .im-box {
    background: #fff;
    // border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    &-header {
      background-color: #43b4ea;
      color: #fff;
      display: flex;
      &-item {
        padding: 10px 30px;
      }
      .active-item {
        background-color: green;
      }
      .im-status {
        padding-right: 10px;
        display: flex;
        align-items: center;
        margin-left: auto;
      }
    }
    &-content {
      display: flex;
      height: 100%;
      &-tools {
        padding: 10px;
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-item {
          width: 23px;
          height: 23px;
          margin-bottom: 10px;
        }
      }
      .im-box-content-list {
        max-height: calc(100% - 80px);
        overflow: scroll;
      }
      &-fast {
        width: 300px;
        background-color: #f7f7f7;
        padding: 0 20px;
        &-head {
          display: flex;
          align-items: center;
          height: 40px;
          &-item {
            width: 100px;
            height: 20px;
            text-align: center;
            font-weight: 600;
            color: #999;
            cursor: pointer;
          }
          &-item:first-child {
            // border-right: 1px solid #e6e6e6;
            text-align: left;
            width: 70px;
          }
          &-item-active {
            color: #000;
          }
        }
      }
      &-head {
        font-weight: 600;
        height: 40px;
        font-size: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      &-left {
        flex: 1;
        border-left: 1px solid #e6e6e6;
        height: 100%;
        .im-record {
          box-sizing: border-box;
          height: 60%;
          border-bottom: 1px solid #e6e6e6;
          overflow: auto;
          padding: 10px;
          .more {
            margin-bottom: 10px;
            cursor: pointer;
            ::v-deep .el-divider__text {
              color: #999;
            }
          }
          .record-item-me {
            width: auto;
            box-sizing: content-box;
            margin-left: auto;
            margin-bottom: 10px;
            max-width: 50%;
            text-align: right;
          }
          .record-item-other {
            max-width: 50%;
            margin-bottom: 10px;
          }
          .record-item-other-name,
          .record-item-me-name {
            margin-bottom: 5px;
            color: #999;
            font-size: 12px;
          }
          .record-item-other-text {
            display: inline-block;
            background-color: #f3f4f7;
            padding: 5px;
            border-radius: 0 5px 5px 5px;
          }
          .record-item-me-text {
            display: inline-block;
            background-color: #6493fc;
            color: #fff;
            padding: 5px;
            border-radius: 5px 0 5px 5px;
          }
          .record-img {
            max-width: 100%;
          }
        }
        .im-send {
          height: 40%;
          position: relative;
          &-tools {
            height: 50px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0 10px;
            &-item {
              margin-right: 10px;
              width: 32px;
              height: 32px;
            }
          }
          &-content {
            height: calc(100% - 50px);
            position: relative;
            ::v-deep .im-input {
              height: 100%;
            }
            &-tip {
              position: absolute;
              bottom: 10px;
              right: 10px;
              color: #999;
            }
          }

          &-footer {
            position: absolute;
            bottom: 10px;
            right: 10px;
            &-btn {
              line-height: 30px;
              text-align: center;
              width: 50px;
              height: 30px;
              border-radius: 5px;
              background-color: #43b4ea;
              color: #fff;
              cursor: pointer;
            }
          }
        }
      }
      &-right {
        width: 300px;
        overflow-y: auto;
        background-color: #f7f7f7;
        .customer-item {
          cursor: pointer;
          display: flex;
          padding: 10px;
          margin: 0 10px 5px 10px;
          align-items: center;
          border-radius: 8px;
          position: relative;
          .item-no-read {
            font-size: 12px;
            color: #fff;
            padding: 3px;
            background-color: red;
            border-radius: 50%;
          }
          &-left {
            width: 0;
            flex: 1;
            .customer-item-name {
              display: flex;
              margin-bottom: 5px;
              align-items: center;
            }
            .customer-item-content {
              display: flex;
              color: #999;
              &-text {
                flex: 1;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; //溢出不换行
              }
              &-time {
                margin-right: 5px;
              }
            }
          }
          &-right {
            margin-left: 10px;
            .customer-btn {
              background-color: green;
              color: #fff;
              padding: 7px 15px;
              border-radius: 5px;
            }
          }
        }
        .customer-item-active {
          background-color: #e4e4e4;
        }
      }
    }
  }
}
.im-active1 {
  ::v-deep textarea {
    height: 100%;
    border: none;
    outline: none; /*边线不显示*/
    resize: none; /*禁止拉伸*/
    appearance: none;
  }
}
::-webkit-scrollbar {
  display: none;
}
::v-deep .el-divider--horizontal {
  margin: 6px 0;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.margin-l-5 {
  margin-left: 5px;
}
.flex {
  display: flex;
}
.flex1 {
  flex: 1;
}
.set-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .set-label {
    width: 80px;
  }
  .set-input {
    width: 300px;
    margin-right: 10px;
  }
}
.group {
  margin-top: 20px;
  i {
    margin-left: 3px;
  }
  .group-item-f {
    padding: 5px 0;
  }
  .children {
    padding-left: 20px;
    .children-item {
      padding: 5px;
      cursor: pointer;
    }
  }
}
.term {
  padding: 16px;
  overflow: auto;
}
.support {
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #999;
  font-size: 12px;
}
.send-tools-item {
  border: 1px solid #999;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 40px;
  color: #999;
  cursor: pointer;
}
</style>
